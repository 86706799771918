// Import and register all your controllers from the importmap under controllers/*
import { Application } from "@hotwired/stimulus";
import { Alert, Tabs, Popover } from "tailwindcss-stimulus-components";

import change_company_form from "controllers/change_company_form_controller";
import sign_out from "controllers/sign_out_controller";
import app_versions from "controllers/app_versions_controller";
import sidebar from "controllers/sidebar_controller";
import rate_code_form from "controllers/rate_code_form_controller";
import user_search_form from "controllers/user_search_form_controller";
import open_ticket_search_form from "controllers/open_ticket_search_form_controller";
import accordion_controller from "controllers/accordion_controller";
import stripe_card_details_controller from "controllers/stripe_card_details_controller";
import confirm_controller from "controllers/confirm_controller";
import stripe_payment_method_controller from "controllers/stripe_payment_method_controller";
import qr_code_controller from "controllers/qr_code_controller";
import override_password_controller from "controllers/override_password_controller";
import new_payment_form_controller from "controllers/new_payment_form_controller";
import invoice_draft_form_controller from "controllers/invoice_draft_form_controller";
import task_code_form_controller from "controllers/task_code_form_controller";
import confirm_dialog from "controllers/confirm_dialog_controller";
import submit_on_change from "controllers/submit_on_change";
import table_sort from "controllers/table_sort_controller";
import turbo_dialog from "controllers/turbo_dialog_controller";
import row_click from "controllers/row_click_controller";
import turbo from "controllers/turbo";
import multiline from "controllers/multiline_controller";
import link_dialog from "controllers/link_dialog_controller";
import part_order_notifications from "./part_order_notification_controller";
import part_order_presence from "./part_order_presence_controller";
import time_ago from "./time_ago_controller";
import disable_download from "./disable_download";
import mapkit from "./mapkit_controller";
import upload_preview from "controllers/tech_resources_form_controller";
import upload_progress from "controllers/upload_progress_controller";
import timesheet_row from "controllers/timesheet_row_controller";
import tech_form from "controllers/tech_form_controller";
import easepick from "controllers/easepick_controller";
import submit from "controllers/submit_controller";
import dropdown from "controllers/dropdown_controller";
import address_autocomplete from "controllers/address_autocomplete_controller";
import ticket_form from "controllers/ticket_form_controller";

import { StripeConstructor } from "@stripe/stripe-js";

declare global {
	interface Window {
		passwordConfirm: (
			title: string
		) => Promise<{ didSubmit: boolean; password: string }>;
		confirmAction: (dialogText: string) => Promise<boolean>;
		Stripe?: StripeConstructor;
		Stimulus: ReturnType<typeof Application.start>;
		Turbo: {
			visit(url: string): void;
			setConfirmMethod(
				fn: (text: string, el: HTMLElement) => Promise<boolean>
			): void;
		};
		toast: ({
			text,
			type,
		}: {
			text: string;
			type?: "error" | "notice" | "info";
		}) => void;
		googlePlacesReady: () => void;
	}
}

window.Stimulus = Application.start();
window.Stimulus.debug = !window.location.hostname.includes("4abc");

window.Stimulus.register("upload-preview", upload_preview);
window.Stimulus.register("upload-progress", upload_progress);
window.Stimulus.register("override-password", override_password_controller);
window.Stimulus.register("change-company-form", change_company_form);
window.Stimulus.register("new-payment-form", new_payment_form_controller);
window.Stimulus.register("app-versions", app_versions);
window.Stimulus.register("sign-out", sign_out);
window.Stimulus.register("alert", Alert);
window.Stimulus.register("tabs", Tabs);
window.Stimulus.register("popover", Popover);
window.Stimulus.register("confirm", confirm_controller);
window.Stimulus.register("sidebar", sidebar);
window.Stimulus.register("rate-code-form", rate_code_form);
window.Stimulus.register("user-search-form", user_search_form);
window.Stimulus.register("open-ticket-search-form", open_ticket_search_form);
window.Stimulus.register("accordion", accordion_controller);
window.Stimulus.register("stripe-card-details", stripe_card_details_controller);
window.Stimulus.register("invoice-draft-form", invoice_draft_form_controller);
window.Stimulus.register("task-code-form", task_code_form_controller);
window.Stimulus.register(
	"stripe-payment-method",
	stripe_payment_method_controller
);
window.Stimulus.register("qr-code", qr_code_controller);
window.Stimulus.register("confirm-dialog", confirm_dialog);
window.Stimulus.register("submit-on-change", submit_on_change);
window.Stimulus.register("table-sort", table_sort);
window.Stimulus.register("turbo-dialog", turbo_dialog);
window.Stimulus.register("row-click", row_click);
window.Stimulus.register("turbo", turbo);
window.Stimulus.register("multiline", multiline);
window.Stimulus.register("link-dialog", link_dialog);
window.Stimulus.register("part-order-notifications", part_order_notifications);
window.Stimulus.register("part-order-presence", part_order_presence);
window.Stimulus.register("time-ago", time_ago);
window.Stimulus.register("disable-download", disable_download);
window.Stimulus.register("mapkit", mapkit);
window.Stimulus.register("timesheet-row", timesheet_row);
window.Stimulus.register("tech-form", tech_form);
window.Stimulus.register("easepick", easepick);
window.Stimulus.register("submit", submit);
window.Stimulus.register("dropdown", dropdown);
window.Stimulus.register("address-autocomplete", address_autocomplete);
window.Stimulus.register("ticket-form", ticket_form);

window.toast = ({ text, type = "notice" }) => {
	const flashTemplate = document.querySelector("template#flash_template") as
		| HTMLTemplateElement
		| undefined;
	if (flashTemplate) {
		const classes = {
			notice: "bg-green-200 text-green-600",
			error: "bg-red-100 text-red-800",
			info: "bg-blue-100 text-blue-800",
		}[type];
		const temp = document.importNode(flashTemplate.content, true);
		temp.querySelector(`[data-id=${type}]`)?.classList?.remove("hidden");
		const alertText = temp.querySelector(`[data-id=alert-text]`) as
			| HTMLElement
			| undefined;
		if (alertText) {
			alertText.innerText = text;
		}
		temp
			.querySelector(`[data-controller=alert]`)
			?.classList?.add(...classes.split(" "));

		let toastTarget = document.body;
		document.querySelectorAll("dialog").forEach((dialog) => {
			if (dialog.open) {
				toastTarget = dialog;
			}
		});

		toastTarget.appendChild(temp);
	}
};

const confirmMethod = (
	title: string,
	element: HTMLElement
): Promise<boolean> => {
	return new Promise((resolve) => {
		const dialogTemplate = document.querySelector(
			"template#confirm_template"
		) as HTMLTemplateElement;
		const temp = document.importNode(dialogTemplate.content, true);
		const dialog = temp.querySelector("dialog") as HTMLDialogElement;
		dialog.dataset.confirmDialogTitleValue = title || "Confirm";
		dialog.dataset.confirmDialogBodyValue =
			element.dataset.body || "Are you sure?";
		dialog.dataset.confirmDialogClassesValue = element.dataset.classes || "";
		dialog.dataset.confirmDialogButtonValue = element.dataset.button || "OK";
		dialog.addEventListener("close", () => {
			resolve(dialog?.returnValue === "confirm");
		});
		document.body.appendChild(temp);
	});
};

const confirmAction = (dialogText: string): Promise<boolean> => {
	return new Promise((resolve) => {
		const dialogTemplate = document.querySelector(
			"template#confirm_template"
		) as HTMLTemplateElement;
		const temp = document.importNode(dialogTemplate.content, true);
		const dialog = temp.querySelector("dialog") as HTMLDialogElement;
		dialog.dataset.confirmDialogTitleValue = "Please Confirm";
		dialog.dataset.confirmDialogBodyValue = dialogText;
		dialog.dataset.confirmDialogClassesValue = "";
		dialog.dataset.confirmDialogButtonValue = "CONFIRM";

		dialog.querySelector("form")?.addEventListener("submit", (e) => {
			const didSubmit =
				(e.submitter as HTMLInputElement | null)?.value === "confirm";
			resolve(didSubmit);
		});
		dialog
			.querySelector("button[value='cancel']")
			?.addEventListener("click", (e) => {
				e.preventDefault();
				dialog.close();
			});
		document.body.appendChild(temp);
	});
};

const passwordConfirm = (
	title: string
): Promise<{ didSubmit: boolean; password: string }> => {
	return new Promise((resolve) => {
		const dialogTemplate = document.querySelector(
			"template#confirm_template"
		) as HTMLTemplateElement;
		const temp = document.importNode(dialogTemplate.content, true);
		const dialog = temp.querySelector("dialog") as HTMLDialogElement;
		dialog.dataset.confirmDialogTitleValue = "Password Required";
		dialog.dataset.confirmDialogBodyValue = title;
		dialog.dataset.confirmDialogClassesValue = "";
		dialog.dataset.confirmDialogButtonValue = "SUBMIT";

		const input = document.createElement("input");
		input.setAttribute("type", "password");
		input.setAttribute("placeholder", "Password");
		input.setAttribute("autofocus", "true");
		input.classList.add("input-field");
		const div = document.createElement("div");
		div.classList.add("p-4", "pt-0");
		div.appendChild(input);

		const hr = temp.querySelector("[data-confirm-dialog-target='body'] + hr");
		hr?.parentNode?.insertBefore(div, hr);

		dialog.dataset.confirmDialogButtonValue = "SUBMIT";
		dialog.querySelector("form")?.addEventListener("submit", (e) => {
			const didSubmit =
				(e.submitter as HTMLInputElement | null)?.value === "confirm";
			const password =
				(
					(e.currentTarget as HTMLDialogElement | null)?.querySelector(
						"input[type='password']"
					) as HTMLInputElement | null
				)?.value || "";
			resolve({ didSubmit, password });
		});
		dialog
			.querySelector("button[value='cancel']")
			?.addEventListener("click", (e) => {
				e.preventDefault();
				dialog.close();
			});
		document.body.appendChild(temp);
	});
};

const PLACES_READY = new CustomEvent("google-places-ready", {
	bubbles: true,
	cancelable: false,
});

const googlePlacesReady = () => {
	document.dispatchEvent(PLACES_READY);
};

window.googlePlacesReady = googlePlacesReady;
window.passwordConfirm = passwordConfirm;
window.confirmAction = confirmAction;
window.Turbo.setConfirmMethod(confirmMethod);
