import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["ticketProblemSelect"];

	declare readonly ticketProblemSelectTarget: HTMLSelectElement;

	selectTicketProblem(event: InputEvent) {
		const problemCode = (event.target as HTMLSelectElement).value;

		this.toggleTicketProblems(problemCode);
	}

	removeTicketProblem(event: MouseEvent) {
		const icon = event.target as HTMLOrSVGImageElement;
		const button = icon.parentElement as HTMLButtonElement;
		const problemCode = button.dataset.problemCode as string;

		this.toggleTicketProblems(problemCode);
	}

	toggleTicketProblems(problemCode: string) {
		for (const option of this.ticketProblemSelectTarget.options) {
			if (option.value === problemCode) {
				option.selected = !option.selected;
			}
		}
	}
}
