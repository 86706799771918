import { Controller } from "@hotwired/stimulus";

function showUploadWindow(title?: string) {
	if (document.querySelectorAll("#upload-progress-modal").length > 0) {
		return;
	}

	const template = document.querySelector<HTMLTemplateElement>(
		"template#upload-progress-template"
	);
	if (template) {
		const content: Node = template.content;
		const alt = document.importNode(content, true) as Element;
		const button = alt?.querySelector("button");
		if (button) {
			button.addEventListener("click", () => {
				hideUploadWindow();
			});
		}

		document.body.appendChild(alt);
		updateUploadTitle(title);
	}
}

function hideUploadWindow() {
	const modal = document.querySelector("#upload-progress-modal");
	if (modal) {
		modal.parentElement?.removeChild(modal);
	}
}

function updateUploadTitle(title?: string) {
	const alertTitle = document.querySelector("#alert-title") as HTMLElement;
	if (alertTitle && title) {
		alertTitle.innerText = title;
	}
}

function updateUploadProgress(progress = 0) {
	const progressMeter = document.querySelector("#progress-meter");
	progressMeter?.setAttribute("style", `width: ${progress}%`);

	const progressText = document.querySelector("#progress-text") as HTMLElement;
	if (progressText) {
		progressText.innerText = `${progress.toFixed()}% Complete`;
	}
}

export default class extends Controller {
	connect() {
		document.addEventListener("direct-uploads:start", function (e) {
			showUploadWindow("Uploading...");
		});
		document.addEventListener("direct-upload:progress", function (e) {
			// @ts-ignore
			const progress = e.detail.progress as number;
			updateUploadProgress(progress);
		});
		document.addEventListener("direct-uploads:end", function (e) {
			updateUploadTitle("Upload complete");

			setTimeout(hideUploadWindow, 100);
		});
		document.addEventListener("direct-upload:error", function (e) {
			updateUploadTitle("Error uploading file");
		});
		document.addEventListener("direct-upload:title", function (e) {
			// @ts-ignore
			updateUploadTitle(e.detail.title);
		});
	}
}
