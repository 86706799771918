import { Controller } from "@hotwired/stimulus";
import { easepick, RangePlugin } from "@easepick/bundle";

export default class extends Controller {
	declare picker: easepick.Core;

	connect() {
		this.picker = new easepick.create({
			element: this.element as HTMLElement,
			css: [
				"https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css",
			],
			format: "M/D/YYYY",
			zIndex: 10,
			plugins: [RangePlugin],
			RangePlugin: {
				delimiter: " to ",
			},
		});

		this.picker.on("select", this.dispatchChange);
	}

	disconnect() {
		this.picker.off("select", this.dispatchChange);
	}

	dispatchChange = () => {
		this.element.dispatchEvent(new Event("change", { bubbles: true }));
	};
}
