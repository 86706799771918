import { Controller } from "@hotwired/stimulus";

type AddressMap = {
	streetAddress: string[];
	city: string;
	state: string;
	zip: string;
};

export default class extends Controller {
	static targets = [
		"results",
		"addressLine1Input",
		"cityInput",
		"stateInput",
		"zipInput",
	];

	declare place: typeof google.maps.places.Place;
	declare autocompleteSessionToken: typeof google.maps.places.AutocompleteSessionToken;
	declare autocompleteSuggestion: typeof google.maps.places.AutocompleteSuggestion;

	declare readonly resultsTarget: HTMLDivElement;
	declare readonly addressLine1InputTarget: HTMLInputElement;
	declare readonly cityInputTarget: HTMLInputElement;
	declare readonly stateInputTarget: HTMLSelectElement;
	declare readonly zipInputTarget: HTMLInputElement;

	connect() {
		if (typeof google == "undefined") {
			console.warn("Google Maps JS not loaded");
		} else {
			this.initAutocomplete();
		}
	}

	initAutocomplete() {
		const autocomplete = new google.maps.places.Autocomplete(
			this.addressLine1InputTarget,
			{
				componentRestrictions: { country: "us" },
				strictBounds: true,
				fields: ["address_components"],
				types: ["address"],
			}
		);

		autocomplete.addListener("place_changed", () => {
			const { address_components: addressComponents } = autocomplete.getPlace();

			if (!addressComponents) return;

			const emptyAddress: AddressMap = {
				streetAddress: [],
				city: "",
				state: "",
				zip: "",
			};

			const address = addressComponents.reduce((acc, component) => {
				const { types, short_name: value } = component;

				if (types.includes("street_number") || types.includes("route")) {
					acc.streetAddress.push(value);
				} else if (types.includes("locality")) {
					acc.city = value;
				} else if (types.includes("administrative_area_level_1")) {
					acc.state = value;
				} else if (types.includes("postal_code")) {
					acc.zip = value;
				}

				return acc;
			}, emptyAddress);

			this.autofillAddress(address);
		});
	}

	autofillAddress(address: AddressMap) {
		this.addressLine1InputTarget.value = address.streetAddress.join(" ");
		this.stateInputTarget.value = address.state;
		this.cityInputTarget.value = address.city;
		this.zipInputTarget.value = address.zip;
	}
}
